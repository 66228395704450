<template>
    <b-card class="card-profile" img-height="400">
        <div class="profile-image-wrapper">
            <div class="profile-image p-0">
                <b-avatar size="114" variant="light" :src="user.image" />
            </div>
        </div>
        <h3>{{ user.firstname + ' ' + user.lastname }}</h3>
        <h3 class="text-muted">{{ user.email }}</h3>
        <h6 class="text-muted">
            {{ user.country }}
        </h6>
        <b-badge class="profile-badge" variant="light-primary">
            <!-- Pro Level -->
        </b-badge>
        <hr class="mb-2">

        <!-- follower projects rank -->
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <!-- <h6 class="text-muted font-weight-bolder">
                    Reset Password
                </h6> -->
                <router-link :to="{ name: 'user-edit' }" class="btn btn-primary add-comman-btn" data-aos="fade-up"
                    data-aos-duration="1200" data-aos-delay="400">Reset Password</router-link>
            </div>
            <div>
                <!-- <h6 class="text-muted font-weight-bolder">
                    All Orders
                </h6>
                <h3 class="mb-0">
                    156
                </h3> -->
            </div>
            <div>
                <!-- <h6 class="text-muted font-weight-bolder">
                    Delete User
                </h6> -->
                <!-- <router-link @click="deleteEmployee(props.row.id)" class="btn btn-primary add-comman-btn"
                    data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">Delete User</router-link> -->
                    <button @click="deleteEmployee(user_id)" class="btn btn-primary add-comman-btn" data-aos="fade-up" data-aos-duration="1200"
                data-aos-delay="400">Delete User</button>
            </div>
        </div>
        <!--/ follower projects rank -->
    </b-card>
</template>

<script>
import { BCard, BAvatar, BBadge } from 'bootstrap-vue'

//Database
import { db } from "@/main";
import store from '@/store';

export default {
    components: {
        BCard,
        BBadge,
        BAvatar,
    },
    data: () => {
        return {
            user: {},
            user_id: {},
        }
    },
    methods: {
        getUserDetail() {
            store.dispatch('app/commitActivateLoader');

            this.user = {};
            let id = this.$route.params.id;
            db.collection("users").doc(id).get()
                .then(user => {
                    this.user = user.data();
                    this.user_id = user.id;
                    console.log(this.user_id);
                    store.dispatch('app/commitDeactivateLoader');

                }
                ).catch(err => {
                    console.log(err);
                    store.dispatch('app/commitDeactivateLoader');

                });

        },
        deleteEmployee(id) {
            if (confirm("Do you really want to delete?")) {
                // alert('dddd');
                db.collection("users")
                    .doc(id)
                    .delete()
                    .then(() => {
                        console.log("Document successfully deleted!");
                        // this.readEmployees();
                        // this.showNotification('User deleted successfully', 'UserIcon', 'danger');
                        this.$router.push({ name: 'users-list' });
                    })
                    .catch((error) => {
                        console.error("Error removing document: ", error);
                    });
            }
        },
    },
    created() {
        this.getUserDetail();

    }
}

</script>
